import { useCallback } from 'react';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';

type Props = {
    isOpen: boolean;
    showNodeCreatedUser: boolean;
    onClose(): void;
    onShowNodeCreatedUserChanged(checked: boolean, isLocked: boolean): void;
};

export const ViewSettingModal: React.FC<Props> = ({
    isOpen,
    showNodeCreatedUser,
    onClose,
    onShowNodeCreatedUserChanged,
}: Props) => {
    const handleChangeShowNodeCreatedUser = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onShowNodeCreatedUserChanged(event.target.checked, false);
        },
        [onShowNodeCreatedUserChanged]
    );

    return (
        <GeneralModal
            isOpen={isOpen}
            onClose={onClose}
            title={'設定 | Settings'}
            submitButton={<></>}
            cancelButton={<></>}
        >
            <div className="p-4 pb-16">
                <label>
                    <input
                        type="checkbox"
                        className="form-checkbox size-6 border-brand"
                        checked={showNodeCreatedUser}
                        onChange={handleChangeShowNodeCreatedUser}
                    />
                    <span className="ml-2">作成者のアイコンを表示 | Whose icon</span>
                </label>
            </div>
        </GeneralModal>
    );
};
